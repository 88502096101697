import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    keywords: String,
        page: Number,
  }
  static targets = [ "totalCount" ]

  on_search_gtm() {
    if(this.pageValue == 1) {
      let total_count = this.hasTotalCountTarget ? this.totalCountTarget.textContent : 0
      dataLayer.push({
        event: "search",
        search_term: this.keywordsValue,
        total_count: total_count,
      })
    }
  }
}