import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "child", "child2" ]

  toggleChild(event) {
    this.childTargets.forEach(function (child) {
      child.classList.toggle('d-none');
    });

    this.child2Targets.forEach(function (child2) {
      child2.classList.add('d-none');
    });
  }

  toggleChildSibling(event) {
    event.currentTarget.nextElementSibling.classList.toggle('d-none');
  }
}
