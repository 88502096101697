import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash/debounce");

export default class extends Controller {
    static targets = ['variantId', 'quantity', 'updateForm', 'input', 'toggler', 'editInvoice',
                      'itemQuantity', 'deleteItem', 'invoiceHeader', 'searchForm', 'updateVersion'];

    initialize() {
        this.submit = debounce(this.submit, 700).bind(this);
        this.loading = false;
        this.submitSuccess = false;
    }

    toggle(event) {
        event.preventDefault();
        event.stopPropagation();
        this.togglerTarget.classList.add('d-none');
        this.editInvoiceTarget.classList.remove('d-none');
    }

    editInvoice(event) {
        event.preventDefault();
        event.stopPropagation();

        this.toggleInvoiceInputs();
    }

    toggleInvoiceInputs() {
        this.itemQuantityTargets.forEach((item) => item.classList.add('d-none'));
        this.inputTargets.forEach((item) => item.classList.remove('d-none'));
        this.deleteItemTargets.forEach((item) => item.classList.remove('d-none'));
        this.invoiceHeaderTarget.classList.add('d-none');
        this.searchFormTarget.classList.remove('d-none');
    }

    async submit() {
        this.disposePopovers();
        this.updateFormTarget.requestSubmit();
        if (this.submitSuccess) {
            this.disableInputs();
            this.loading = true;
            this.submitSuccess = false;
        }

    }

    onFormSubmit() {
        this.submitSuccess = true;
    }

    disableInputs() {
        this.inputTargets.forEach((input) => {
            input.disabled = true;
            input.parentNode.classList.add('loading');
        });
    }

    disposePopovers() {
        document.dispatchEvent(new Event('custom:dispose-popovers'));
    }

    async add(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.variantIdTarget.value == '') { return }

        const formData = new FormData();
        formData.append('variant_id', this.variantIdTarget.value);
        formData.append('quantity', this.quantityTarget.value);
        formData.append('authenticity_token', AUTH_TOKEN);
        formData.append('update_version', this.updateVersionTarget.value);

        this.processEventForRequest(event, formData, 'POST');
    }

    async remove(event) {
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('variants[][variant_id]', event.params.id);
        formData.append('variants[][quantity]', event.params.quantity);
        formData.append('authenticity_token', AUTH_TOKEN);
        formData.append('update_version', this.updateVersionTarget.value);

        this.processEventForRequest(event, formData, 'PATCH');
    }

    async processEventForRequest(event, formData, method) {
        if (this.loading) { return }

        this.loading = true;
        this.disableInputs();
        this.disposePopovers();
        try {
            await fetch(event.currentTarget.href, {
                method,
                body: formData,
                headers: {
                    'Accept': 'text/vnd.turbo-stream.html',
                }
            }).then(r => r.text())
              .then(html => Turbo.renderStreamMessage(html))

            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    connect() {
        this.customLoadListener = () => {
            this.loading = false;
        };

        document.addEventListener("custom:load", this.customLoadListener);
    }

    disconnect() {
        if (this.customLoadListener) {
            document.removeEventListener("custom:load", this.customLoadListener);
        }
    }
}
