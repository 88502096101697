import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["count"]

  set count(count) {
    this.countTarget.textContent = count
    this.countTarget.classList.add("badge-pop")
    this.countTarget.classList.remove("d-none")
    setTimeout(() => this.countTarget.classList.remove("badge-pop"), 600)
  }
}
