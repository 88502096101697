import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["variantSearch"]
    static values = { orderNumber: String }

    get select() {
        return $(this.variantSearchTarget);
    }

    connect() {
        const orderNumber = this.orderNumberValue
        this.select.select2({
            placeholder: "Search by part number",
            minimumInputLength: 4,
            ajax: {
                url: '/variants/exact_search.json',
                data: function (term, page) {
                    return {
                        keywords: term,
                        page_limit: 10,
                        authenticity_token: AUTH_TOKEN,
                        order_number: orderNumber
                    };
                },
                results: function (data, page) {
                    return {results: data};
                }
            },
            id: function(item) {
                return item.variant.id;
            },
            formatSelection: function(item) {
                let markup = '';
                markup += '<div class="row">'
                markup += '  <div class="col-12 col-sm-8 col-lg-4">'
                markup +=      item.variant.number + (item.variant.brand ? (' - ' + item.variant.brand.name) : '')
                markup += '  </div>'
                markup += '  <div class="col-lg-4 d-none d-lg-block" style="overflow: hidden;">'
                markup += '    <span class="text-muted">' + item.variant.name + '</span>';
                markup += '  </div>'
                markup += '  <div class="col-lg-2 d-none d-lg-block">'
                markup += '    <small class="text-muted">' + item.variant.ships_in + '</small>'
                markup += '  </div>'
                markup += '  <div class="col-sm-4 col-lg-2 d-none d-sm-block">'
                markup += '    <span class="badge">€' + item.variant.price + '</span>'
                markup += '  </div>'
                markup += '</div>';
                return markup;
            },
            formatResult: function(item) {
                let markup = ''
                markup += '<div class="row">';
                markup += '  <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap" class="col-12 col-lg-4">'
                markup += '    ' + item.variant.number + (item.variant.brand ? (' - ' + item.variant.brand.name) : '')
                markup += '  </div>'
                markup += '  <div class="col-7 col-lg-4" style="overflow: hidden;">'
                markup += '    <span class="text-muted">' + item.variant.name + '</span>';
                markup += '  </div>'
                markup += '  <div class="col-2 col-lg-2">'
                markup += '    <small class="text-muted">' + item.variant.ships_in + '</small>'
                markup += '  </div>'
                markup += '  <div class="col-3 col-lg-2">'
                markup += '    <small class="text-muted">€' + item.variant.price + '</small>'
                markup += '  </div>'
                markup += '</div>'
                return markup;
            },
            dropdownCssClass: "bigdrop"
        });
    }

    disconnect() {
        this.select.select2('destroy');
    }
}
