import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "keywords" ]

  before_render(event) {
    // Saving the initial state of the keyword target. When user types the search query
    // then value is changed. After that the page is cached by Turbo and it has already
    // modified value. When user uses navigation and return back, then input contains
    // dirty value.
    //
    // We will restore it to defaul before every render.
    event.detail.newBody.querySelectorAll('[data-search-target]').forEach((input) =>
      input.value = (input.dataset.default === undefined ? input.value : input.dataset.default)
    )
  }
}