import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "hiddenInput" ]
    static values = { field: String }

    change(event) {
        event.preventDefault();
        this.hiddenInputTarget.value = event.target.dataset[this.fieldValue];
        this.element.submit();
    }
}
