import {Popover, Dropdown} from 'bootstrap'

document.addEventListener("turbo:load", () => {

  let allowList = Popover.Default.allowList
  allowList.table = []
  allowList.thead = []
  allowList.tbody = []
  allowList.tr = []
  allowList.td = []

  Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(el => new Popover(el, {allowList: allowList}) )
  Array.from(document.querySelectorAll('.dropdown-toggle')).forEach(el => new Dropdown(el))
})

document.addEventListener("turbo:before-cache", () => {
  Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(el => Popover.getInstance(el).dispose() )
  Array.from(document.querySelectorAll('.dropdown-toggle')).forEach(el => Dropdown.getInstance(el).dispose() )
})

