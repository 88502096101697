import lozad from 'lozad'

const observer = lozad('.lozad');

document.addEventListener('turbo:load', initLozad);
document.addEventListener('turbo:frame-render', initLozad);

function initLozad(event) {
  observer.observe();
}
