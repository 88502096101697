import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "contentNav" ]

  connect () {
    $('body').scrollspy({target: '#content-navbar', offset: 200})
    this.decorate_main_nav()
    this.display_content_nav()
  }

  scroll(event) {
    document.querySelector(event.currentTarget.attributes.href.value).scrollIntoView({ behavior: 'smooth', block: 'start'})
  }

  onScroll(_event) {
    this.decorate_main_nav()
    this.display_content_nav()
  }

  decorate_main_nav() {
    if(scrollY > 0)
      this.element.classList.add('shadow-sm', 'border-bottom')
    else
      this.element.classList.remove('shadow-sm', 'border-bottom')
  }

  display_content_nav() {
    if(this.hasContentNavTarget) {
      let elem = document.querySelector('.inline-navbar')
      let offset = 0;
      do {
        offset += elem.offsetTop;
        elem = elem.offsetParent;
      } while (elem)

      if(scrollY > offset) {
        this.contentNavTarget.classList.remove('d-none')
      } else {
        this.contentNavTarget.classList.add('d-none')
      }
    }
  }
}
