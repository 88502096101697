import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["countryIso2", "countryIcon", 'country', 'loading']

  on_country_select(event) {
    let e = event.detail.event.clickEvent;
    if (e && e.target && e.target.dataset.iso2) {
      this.countryTarget.value           = e.target.dataset.iso2
      this.countryIso2Target.textContent = e.target.dataset.iso2.toUpperCase()
      this.countryIconTarget.innerHTML   = e.target.querySelector('.icon').outerHTML
    }
  }

  display_loading() {
    this.loadingTarget.classList.remove('d-none');
  }
}