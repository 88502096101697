/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'

import { appsignal } from "../src/appsignal"
import { installErrorHandler } from "@appsignal/stimulus"

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import * as Turbo from "@hotwired/turbo"
import { PageRenderer } from "@hotwired/turbo"

import LocalTime from "local-time"
import Rails from 'rails-ujs'
import 'select2';

appsignal.wrap(() => {

  // Turbo replace the entire body with DOM. However the HelpScout do not work well with that,
  // because it creates the iframe inside of body tag and the turbo:visit will brake the widget.
  // We will use the #turbo-drive-body div as the container for turbo body DOM.
  // https://github.com/hotwired/turbo/pull/305
  //
  const nextEventLoopTick = () => new Promise((resolve) => {
    setTimeout(() => resolve(), 0);
  });
  const findBodyElement = (body) => body.querySelector("#turbo-drive-body") || body;
  PageRenderer.prototype.assignNewBody = async function assignNewBody() {
    await nextEventLoopTick();
    findBodyElement(document.body).replaceWith(findBodyElement(this.newElement))
  }

  window.Stimulus = Application.start()
  // window.Stimulus.debug = true
  installErrorHandler(appsignal, window.Stimulus)
  const context = require.context("../controllers", true, /_controller\.js$/)
  window.Stimulus.load(definitionsFromContext(context))

  LocalTime.start()
  Rails.start();

  require("../src/lozad")
  require("../src/jquery_delegate")
  require("../src/bootstrap")
})
