import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    gtmItem: Object,
    price: Number,
    discount: Number,
    currency: String,
  }
  static targets = ["quantity"]
  static outlets = ["cart-badge"]

  on_success(event) {
    let [data, status, xhr] = event.detail
    this.cartBadgeOutlet.count = data['item_count']
  }

  on_gtm_add_cart() {
    let item = this.gtmItemValue
    item.quantity = parseInt(this.quantityTarget.value)
    item.price = this.priceValue
    if(this.discountValue > 0) {
      item.discount = this.discountValue
    }

    let value = (item.price - this.discountValue) * item.quantity

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: this.currencyValue,
           value: value,
           items: [item]
      }
    })
  }
}
