import Appsignal from "@appsignal/javascript"
import { plugin as path_decorator} from "@appsignal/plugin-path-decorator"

const appsignal = new Appsignal({
  key: document.querySelector('meta[name="appsignal_key"]').content,
  revision: document.querySelector('meta[name="appsignal_revision"]').content,
})
appsignal.use(path_decorator())
appsignal.addDecorator((span) => {
  return span.setTags({request_id: document.querySelector('meta[name="request_id"]').content });
})

document.addEventListener('turbo:load', (event) => {
  appsignal.wrap(() => {
    appsignal.addBreadcrumb({category: "Turbo", action: "turbo:load: " + event.detail.url})
    if(event.detail.url.match(/aechahp2thu1ei5Ofee6uiz5chohthoh/)) {
      throw new Error("Test error");
    }
  })
})

export {appsignal}
