import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    identify: Object,
    beaconId: String,
    sessionData: Object,
  }

  loadScript() {
    !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){})
  }

  initialize() {
    this.loadScript()
    window.Beacon('init', this.beaconIdValue)
    window.Beacon('identify', this.identifyValue)
    window.Beacon('session-data', this.sessionDataValue)
  }

  visit() {
    window.Beacon('event', {
       type: 'page-viewed',
        url: document.location.href,
      title: document.title,
    })
  }

  showOeMessage() {
    let lang = navigator.language || navigator.userLanguage;
    if (lang.startsWith('fr')) {
      window.Beacon('show-message', '63c65dec-d782-49ea-89ac-1a65129ec61e', { force: true })
    } else if(lang.startsWith('es')) {
      window.Beacon('show-message', '685b22c1-0157-4adf-87af-9037e3f5d346', { force: true })
    } else {
      window.Beacon('show-message', 'fe021f01-5306-46b0-9372-639fbc9d2c71', { force: true })
    }
  }
}
